import React from 'react'
import TextCard from '~/components/molecules/TextCard'
import PricingForm from '~/components/organisms/PricingForm'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function HeroPricing(page) {

  const section = page.heroPricing

  return (
    <section className="mb-24 md:mb-40 py-40 pb-20 md:pb-8 bg-sand relative">
      {/* <div className="bg-sand absolute inset-0 bottom-8"></div> */}
      <div className="container">
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-12 md:col-span-5 mb-10 md:mb-12 grid md:grid-cols-5 h-full items-center">
            <TextCard className="text-center col-span-5 " {...section.textCard} />
          </div>
          <FadeInWhenVisible className="col-span-12 md:col-span-6 md:col-start-7 -mb-32">
            <PricingForm className="bg-white border border-[#EFEEEA]" />
          </FadeInWhenVisible>
        </div>
      </div>
    </section>
  )
}
