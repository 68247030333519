import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import TextCard from '~/components/molecules/TextCard';
import ArticlePreview from '~/components/molecules/ArticlePreview';

export default function LatestResources(props) {
  const section = props.latestResources;
  const resourceType = section.resourceType || 'all';

  const {allResources, partnerResources, residentialResources } = useStaticQuery(graphql`
    query {
      allResources: allWpPost(
        limit: 3,
        sort: { fields: date, order: DESC },
        filter: {
          categories: { nodes: { elemMatch: { name:{ nin: [ "Uncategorized", "Uncategorised"] } }}},
        }
      ) {
        nodes {
          ...PostPreview
        }
      }

      partnerResources: allWpPost(
        limit: 3,
        sort: { fields: date, order: DESC },
        filter: {
          categories: { nodes: { elemMatch: { name:{ nin: [ "Uncategorized", "Uncategorised"] } }}},
          resourceTypes: { nodes:{ elemMatch:{ slug:{ eq: "partner" }}}}
        }
      ) {
        nodes {
          ...PostPreview
        }
      }

      residentialResources: allWpPost(
        limit: 3,
        sort: { fields: date, order: DESC },
        filter: {
          categories: { nodes: { elemMatch: { name:{ nin: [ "Uncategorized", "Uncategorised"] } }}},
          resourceTypes: { nodes:{ elemMatch:{ slug:{ eq: "residential" }}}}
        }
      ) {
        nodes {
          ...PostPreview
        }
      }
    }
  `);

  const posts = {
    all: allResources,
    partner: partnerResources,
    residential: residentialResources
  }

  const filteredResources = posts[resourceType];

  return (
    <section className={`my-20 md:my-36`}>
      <div className="container">
        <header className="mb-16 md:mb-20">
          <TextCard
            className="grid grid-cols-12 w-full items-end"
            headingClassName="!mb-0"
            innerClassName="col-span-12 md:col-span-9"
            buttonContainerClassName="col-span-12 md:col-span-2 md:col-start-11 md:text-right"
            {...section.textCard}
          />
        </header>
        <div className="grid grid-cols-12 gap-y-16 md:gap-12">
          {section?.customResources?.map((item, i) => (
            <ArticlePreview key={`preview${item.id}`} {...item.resource} className="col-span-12 md:col-span-4" />
          ))}
          {filteredResources.nodes.slice(section?.customResources?.length).map((item, i) => (
            <ArticlePreview key={`preview${item.id}`} {...item} className="col-span-12 md:col-span-4" />
          ))}
        </div>
      </div>
    </section>
  );
}
