import React, {useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper/core';
import TextCard from '~/components/molecules/TextCard';
import Image from '~/components/atoms/Image';
import Arrow from '~/components/atoms/Arrow';
import IconPin from '~/components/atoms/IconPin';
import FadeInWhenVisible from '~/components/FadeInWhenVisible'
import useScrollToNextSection from '~/hooks/useScrollToNextSection';

import 'swiper/swiper.min.css';
import 'swiper/components/pagination/pagination.min.css';

export default function HeroSlider(props) {
  const { ref, scrollToNextSection } = useScrollToNextSection();

  SwiperCore.use([Pagination]);

  const section = props.heroSlider
  const [swiperIndex, setSwiperIndex] = useState(0)


  return (
    <section ref={ref} className="mb-20 md:mb-36 py-20 pt-32 md:pb-0 lg:py-0 lg:pt-20 bg-sand">
      <div className="container">
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-12 md:col-span-5 mb-10 md:mb-0 grid md:grid-cols-5 h-full items-center">
            <TextCard
              className="col-span-5 lg:pt-32 mt-auto"
              {...section.textCard}/>
            <FadeInWhenVisible>
              <button className="mb-10 mt-20" onClick={scrollToNextSection}>
                <Arrow color="navy" className="!w-12 !h-12" />
              </button>
            </FadeInWhenVisible>
          </div>
          <div className="col-span-12 md:col-span-6 md:col-start-7 md:w-[calc(100%+4rem)] xl:w-[calc(100%+7.5rem)]">
            <Swiper
              pagination={{
                type: 'fraction',
                el: '.swiper-pagination',
              }}
              className="w-full flex flex-col"
              slidesPerView={`auto`}
              onSlideChange={(swiper) => setSwiperIndex(swiper.activeIndex)}
              grabCursor="1"
            >
              {section.images.map((item, i) => (
                <SwiperSlide className="" key={`swiper${i}`} style={{ height: 'auto' }}>
                  <Image data={item.image} className={`w-full h-full md:min-h-[44rem]`} />
                </SwiperSlide>
              ))}
              <div className="bg-sand-light w-full md:w-2/3 ml-auto py-4 px-4 min-h-[3rem] flex items-center">
                {section.images.map((item, i) => (
                  <div key={`swiperLocation${i}`} >
                    {item.location && (
                      <div key={`swiperLocation${i}`} className={`flex items-center ${swiperIndex === i ? 'block' : 'hidden'}`} key={i}>
                        <IconPin className="mr-2" />
                        {item.location}
                      </div>
                    )}
                  </div>
                ))}

                {section.images.length > 1 && (
                  <div className="text-h6 inline-block ml-auto">
                    <div className="swiper-pagination text-navy text-opacity-30"></div>
                  </div>
                )}
              </div>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
}
