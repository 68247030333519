import React from "react";
import Image from '~/components/atoms/Image'
import TextCard from "~/components/molecules/TextCard";
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Cta(props) {

  const section = props.cta

  return ((section?.textCard?.length > 0) || (section?.illustration)) ? (
    <section className="my-20 md:my-36">
      <FadeInWhenVisible className="container">
        <div className="grid grid-cols-12 items-center bg-navy text-white px-10 py-20 lg:py-0 lg:px-0">
          <div className="col-span-12 lg:col-span-6 ">
            {section?.illustration && <Image data={section.illustration} className={`${section.illustrationSize === 'normal' ? 'w-40 md:w-64 md:mx-auto' : 'w-full'} `} />}
          </div>
          <div className="col-span-12 lg:col-span-6  lg:p-20 lg:px-24 flex flex-col justify-center items-start">
            <TextCard {...section?.textCard} />
          </div>
        </div>
      </FadeInWhenVisible>
    </section>
  ) : null;
}
