import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Scrollbar} from 'swiper';
import 'swiper/components/scrollbar/scrollbar.scss';
import 'swiper/swiper.scss';
import IconStar from '~/components/atoms/IconStar'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Reviews(props) {

  SwiperCore.use([Scrollbar]);

  const section = props.reviews

  return (
    <FadeInWhenVisible className="my-20 md:my-36 py-20 md:py-24 service-swiper overflow-hidden bg-sand">
      <div className="container">
        <FadeInWhenVisible>
          <h5 className="text-h5 mb-12 text-center">{section.heading}</h5>
        </FadeInWhenVisible>
        <Swiper
          className="w-full flex flex-col"
          slidesPerView={`auto`}
          grabCursor="1"
          freeMode="1"
          scrollbar={{ draggable: true }}
          style={{ overflow: `visible` }}>
          {section.reviews.map((review, i) => (
            <SwiperSlide
              className="max-w-[80%] mr-8 md:max-w-xl last:mr-0"
              key={`hpswiper${i}`}
              style={{ height: 'auto' }}>
              <FadeInWhenVisible className="bg-white h-full flex flex-col justify-center px-8 py-12 md:px-28 md:py-16 text-center">
                <div className="mb-8 flex justify-center space-x-1">
                  {[...Array(parseInt(review.stars))].map((item, i) => (
                    <div className="text-blue" key={i}>
                      <IconStar />
                    </div>
                  ))}
                </div>
                <p className="mb-8">{review.review}</p>
                <div className="font-medium">{review.author.name}</div>
                <div>{review.author.borough}</div>
              </FadeInWhenVisible>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </FadeInWhenVisible>
  );
}

