import React from 'react';
import TextCard from '~/components/molecules/TextCard';
import Image from '~/components/atoms/Image';
import Arrow from '~/components/atoms/Arrow';
import FadeInWhenVisible from '~/components/FadeInWhenVisible'
import useScrollToNextSection from '~/hooks/useScrollToNextSection';

export default function HeroTwoColumn(props) {
  const section = props.heroTwoColumn

  const { ref, scrollToNextSection } = useScrollToNextSection();

  return (
    <section ref={ref} className={`mb-20 md:mb-36 py-40 pb-20 pt-32 md:pb-0 lg:py-0 lg:pt-20 ${section.backgroundColour ? `bg-${section.backgroundColour}` : 'bg-sand'} ${section.backgroundColour === 'navy' && 'text-white'} `}>
      <div className="container">
        <div className="grid grid-cols-12 items-center">
          <div className="col-span-12 md:col-span-5 mb-10 md:mb-0 ">
            <div className="grid md:grid-cols-5 h-full items-center">
              <TextCard
                className="col-span-5"
                {...section.textCard}
              />
            </div>
            {section.showArrow && (
              <FadeInWhenVisible className="mt-20">
                <button className="" onClick={scrollToNextSection}>
                  <Arrow color="white" className="!w-12 !h-12" />
                </button>
              </FadeInWhenVisible>
            )}
          </div>
          <div className="col-span-12 md:col-span-6 md:col-start-7 md:w-[calc(100%+4rem)] xl:w-[calc(100%+7.5rem)]">
            <Image loading={'eager'} data={section.image} className={`w-full h-full md:min-h-[50rem] ${section.backgroundColour === 'navy' && 'md:-mb-10'}`} />
            {!section.backgroundColour === 'navy' && (
              <div className="bg-sand-light hidden md:block w-full md:w-2/3 ml-auto min-h-[3.5rem] flex items-center"></div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
