import React from 'react';
import { useField } from 'formik';

export default function SelectField(props) {
  const { label, options, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  const divclassname = props.divclassname
  const labelClassName = props.labelClassName
  const inputClassName = props.inputClassName
  const disabled = props.disabled

  return (
    <div
      className={`flex items-center relative group ${divclassname} ${
        !isInvalid ? 'border-grey focus-within:border-blue' : 'border-[#ff0000]'
      }`}
    >

      <label className={`text-sm absolute top-1/2 transform -translate-y-10 ${disabled && 'text-navy text-opacity-30'} ${labelClassName}`}>{label}</label>
      <select className={`bg-transparent border-b hover:border-opacity-100 outline-none ${disabled && 'opacity-30 hover:border-opacity-30'} focus:border-opacity-100 transition-colors duration-200 ease-in-out py-3.5 px-0 appearance-none w-full border-b-navy border-opacity-30  ${inputClassName}`} {...field} {...other} defaultValue="">
        <option value="" disabled>Please Select</option>
        {options?.length > 0 && options.map((option) => (
          <option
            key={option.value}
            value={option.value}
            dangerouslySetInnerHTML={{ __html: option.label }}
          />
        ))}
      </select>
    </div>
  );
}
