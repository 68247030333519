import React from 'react'
import TextLink from '~/components/atoms/TextLink'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Intro(props) {

  const section = props.intro

  return (
    <section className="my-20 md:my-36">
      <div className="container">
        <div className="lg:grid grid-cols-12">
          <FadeInWhenVisible className="col-span-2 xl:col-start-2 lg:border-r border-r-navy border-opacity-10">
            <h6 className=" text-h6 text-navy text-opacity-50 mb-10 lg:mb-0">{section.subheading}</h6>
          </FadeInWhenVisible>
          <div className="col-span-9 xl:col-span-8 col-start-4 xl:col-start-5">
            <FadeInWhenVisible transition={{delay: '.1'}}>
              <h2 className="intro-title mb-10">{section.heading}</h2>
            </FadeInWhenVisible>
            <FadeInWhenVisible transition={{delay: '.15'}}>
              <TextLink {...section.button} />
            </FadeInWhenVisible>
          </div>
        </div>
      </div>
    </section>
  )
}
