import React from 'react';
import { useField } from 'formik';

export default function CheckboxField(props) {
  const { label, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  const divclassname = props.divclassname
  const labelClassName = props.labelClassName
  const inputClassName = props.inputClassName
  const name = props.name
  const reverse = props.reverse ? props.reverse : false

  return (
    <div
      className={`flex items-start relative group ${divclassname} ${
        !isInvalid ? 'border-grey focus-within:border-blue' : 'border-[#ff0000]'
      }`}
    >
      <input
        name={name}
        id={name}
        type="checkbox"
        className={`border bg-transparent appearance-none outline-none group-hover:border-opacity-100 focus:border-opacity-100 checked:border-opacity-100 transition-colors duration-200 ease-in-out  ${
          !isInvalid ? 'border-navy focus-within:border-navy' : 'border-[#ff0000]'
        } border-opacity-30 ${reverse && 'order-1'}  ${inputClassName}`}
        style={{flex:'0 0 18px', height: '18px'}}
        {...field}
        {...other}
      />
      <label
        htmlFor={name}
        className={`text-sm cursor-pointer ${reverse ? 'mr-auto' : 'pl-4'} ${labelClassName}`}
        dangerouslySetInnerHTML={{ __html: label }}
      />
    </div>
  );
}
