import React from 'react';
import TwoColumns from '~/components/organisms/TwoColumns.js';
import Testimonial from '~/components/organisms/Testimonial.js';
import Features from '~/components/organisms/Features.js';
import Usps from '~/components/organisms/Usps.js';
import Intro from '~/components/organisms/Intro.js';
import Reviews from '~/components/organisms/Reviews.js';
import FwImage from '~/components/organisms/FwImage.js';
import LatestResources from '~/components/organisms/LatestResources.js';
import Faqs from '~/components/organisms/Faqs.js';
import Hero from '~/components/organisms/Hero.js';
import Press from '~/components/organisms/Press.js';
import Team from '~/components/organisms/Team.js';
import Awards from '~/components/organisms/Awards.js';
import TwoColumnImageFeatures from '~/components/organisms/TwoColumnImageFeatures.js';
import TestimonialsSlider from '~/components/organisms/TestimonialsSlider.js';
import TwoColumnFeatures from '~/components/organisms/TwoColumnFeatures.js';
import Values from '~/components/organisms/Values.js';
import Cta from '~/components/organisms/Cta.js';
import TextImageGrid from '~/components/organisms/TextImageGrid.js';
import Logos from '~/components/organisms/Logos.js';
import Quote from '~/components/organisms/Quote.js';
import HeroPricing from '~/components/organisms/HeroPricing.js';
import HeroSlider from '~/components/organisms/HeroSlider.js';
import IntroImagesParallax from '~/components/organisms/IntroImagesParallax.js';
import Careers from '~/components/organisms/Careers.js';
import IntroImages from '~/components/organisms/IntroImages.js';
import HeroTwoColumn from '~/components/organisms/HeroTwoColumn.js';
import ImagesSlider from '~/components/organisms/ImagesSlider.js';
import ImageUsps from '~/components/organisms/ImageUsps.js';
import HeroAbout from '~/components/organisms/HeroAbout.js';
import FeaturedStory from '~/components/organisms/FeaturedStory.js';
import FeaturedStories from '~/components/organisms/FeaturedStories.js';
import TextCta from '~/components/organisms/TextCta.js';

const FlexibleContent = ({ content }) => {

  if(!content)
    return null

  return content.map(
    ({ fieldGroupName, ...layoutProps }, index) => {
      /**
       * Default component
       */
      const Default = () => (
        <div>
          In FlexibleContent the mapping of this component is missing: {fieldGroupName}
        </div>
      );

      /**
       * Mapping the fieldGroupName(s) to our components
       */
      const layouts = {
        page_Page_Page_Content_Usps: Usps,
        page_Page_Page_Content_TwoColumns: TwoColumns,
        page_Page_Page_Content_Testimonial: Testimonial,
        page_Page_Page_Content_Features: Features,
        page_Page_Page_Content_Intro: Intro,
        page_Page_Page_Content_Reviews: Reviews,
        page_Page_Page_Content_LatestResources: LatestResources,
        page_Page_Page_Content_FullWidthImage: FwImage,
        page_Page_Page_Content_Faqs: Faqs,
        page_Pricing_Pricing_Content_Faqs: Faqs,
        page_Page_Page_Content_Hero: Hero,
        page_Page_Page_Content_Press: Press,
        page_Page_Page_Content_Team: Team,
        page_Page_Page_Content_Awards: Awards,
        page_Page_Page_Content_TwoColumnFeaturesWImage: TwoColumnImageFeatures,
        page_Page_Page_Content_TestimonialsSlider: TestimonialsSlider,
        page_Page_Page_Content_TwoColumnFeatures: TwoColumnFeatures,
        page_Page_Page_Content_Values: Values,
        page_Page_Page_Content_Cta: Cta,
        page_Page_Page_Content_TextImageGrid: TextImageGrid,
        page_Page_Page_Content_Logos: Logos,
        page_Page_Page_Content_Quote: Quote,
        page_Page_Page_Content_HeroPricing: HeroPricing,
        page_Page_Page_Content_HeroSlider: HeroSlider,
        page_Page_Page_Content_IntroWImagesParallaxing: IntroImagesParallax,
        page_Page_Page_Content_Careers: Careers,
        page_Page_Page_Content_IntroImages: IntroImages,
        page_Page_Page_Content_HeroTwoColumn: HeroTwoColumn,
        page_Page_Page_Content_ImageSlider: ImagesSlider,
        page_Page_Page_Content_ImageUsps: ImageUsps,
        page_Page_Page_Content_HeroAbout: HeroAbout,
        page_Partnerstories_Partners_Content_FeaturedStory: FeaturedStory,
        page_Partnerstories_Partners_Content_Cta: Cta,
        page_Partnerstories_Partners_Content_Quote: Quote,
        page_Partnerstories_Partners_Content_FeaturedStories: FeaturedStories,
        page_Page_Page_Content_TextCta: TextCta,
        page_default: Default,
      };

      /**
       * If layout type is not existing in our mapping, it shows our Default instead.
       */
      const ComponentTag = layouts[fieldGroupName]
        ? layouts[fieldGroupName]
        : layouts['page_default'];

      return React.createElement(ComponentTag, {
        key: fieldGroupName + index,
        ...layoutProps,
      });
    }
  );
};

export default FlexibleContent;

