import React from 'react'
import Usp from '~/components/molecules/Usp'
import TextCard from '../molecules/TextCard'

export default function Usps(props) {

  const section = props.usps
  let colSpan = '4'

  if(section.usps.length % 3 === 1 ){
    colSpan = '3'
  }

  const backgroundColour = section?.backgroundColour

  return (
    <section className={`${backgroundColour?.colour ? `${backgroundColour?.padding?.top === 'normal' && 'pt-20 md:pt-36'} ${backgroundColour?.padding?.bottom === 'normal' && 'pb-20 md:pb-36'}` : 'my-20 md:my-36'} ${backgroundColour?.colour === 'sand' && 'bg-sand'} `}>
      <div className="container">
        <div className="grid grid-cols-12">
          <div className={`col-span-12 ${section.textCard.alignText === 'center' ? '' : 'lg:col-span-6' }`}>
            <TextCard {...section.textCard} className="mb-14" />
          </div>
        </div>
        <div className="grid grid-cols-12 gap-8 md:gap-16">
          {section.usps.map((usp, i) => (
            <Usp className={`col-span-6 lg:col-span-${colSpan}`} {...usp} i={i} key={`usp${i}`} showArrows={section.showArrows} />
          ))}
        </div>
      </div>
    </section>
  )
}
