import React from 'react'
import TextCard from '~/components/molecules/TextCard'
import Image from '~/components/atoms/Image'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Logos(props) {

  const section = props.logos

  return (
    <section className={`my-20 md:my-36`}>
      <div className="container">
        <header className="flex items-center md:mb-12 max-w-2xl mx-auto">
          <TextCard 
            {...section.textCard}
          />
        </header>
        <div className="flex flex-wrap md:flex-nowrap justify-center space-x-8 space-y-8 md:space-y-0 md:space-x-16">
          {section.logos.map((item, i) => (
            <FadeInWhenVisible key={`logo${i}`}>
              <Image data={item.logo} className="w-40" objectFit="contain" objectPosition="center" /> 
            </FadeInWhenVisible>
          ))}
        </div>
      </div>
    </section>
  )
}
