import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import Image from '~/components/atoms/Image'
import TextLink from '~/components/atoms/TextLink'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Usp({className, showArrows = false, i, ...usp}) {
  return (
    <div className={`text-left ${usp.alignText === 'center' && 'md:text-center'} ${className}`}>
      <FadeInWhenVisible  className={`relative `}>
        <Image data={usp.image} className={`mb-8 w-32 h-32 ${usp.imageSize !== 'small' && 'md:w-40 md:h-40'} relative ${usp.alignText === 'center' && 'md:mx-auto'}`} objectFit="contain" objectPosition={`${usp.alignText} center`}/>
        {showArrows && (
          <div className="absolute top-1/2 transform -translate-y-1/2 right-0">
          {i === 0 ? (
            <StaticImage src={'../../assets/images/usp-arrow-1.png'} className="w-20" alt="" />
          ) : i === 1 ? (
            <StaticImage src={'../../assets/images/usp-arrow-2.png'} className="w-12" alt="" />
          ) : null}
          </div>
        )}
      </FadeInWhenVisible>
      <FadeInWhenVisible transition={{delay: '.05'}}>
        <h5 className="text-h5 mb-3">{usp.heading}</h5>
      </FadeInWhenVisible>
      <FadeInWhenVisible transition={{delay: '.1'}}>
        <p>{usp.content}</p>
      </FadeInWhenVisible>
      {usp.link && (
        <FadeInWhenVisible transition={{delay: '.15'}}>
          <TextLink className="mt-6" {...usp.link} />
        </FadeInWhenVisible>
      )}
    </div>
  )
}
