import React from 'react';

export default function TrustPilotWidget({ className }) {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      className={`border text-white border-white border-opacity-20 py-5 pl-8 md:pl-0 pr-8 flex items-center ${className}`}
    >
      <div className="items-center justify-center w-full md:flex">
        <div
          ref={ref}
          class=" text-white trustpilot-widget flex justify-center md:justify-start"
          data-locale="en-GB"
          data-template-id="5419b637fa0340045cd0c936"
          data-businessunit-id="579f04e80000ff000592f5f7"
          data-style-height="20px"
          data-style-width="auto"
          data-theme="dark"
        >
          <a href="https://uk.trustpilot.com/review/cityrelay.com" target="_blank" rel="noopener"></a>
        </div>
        <a
          href="https://uk.trustpilot.com/review/cityrelay.com"
          target="_blank"
          className="block w-full mx-auto mt-2 text-center underline md:ml-auto md:w-auto md:mt-0"
        >
          See more reviews
        </a>
      </div>
    </div>
  );
}
