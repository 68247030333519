import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import InputField from '~/components/atoms/InputField';
import SelectField from '~/components/atoms/SelectField';
import AutocompleteInputField from '~/components/atoms/AutocompleteInputField';
import IncrementorInputField from '~/components/atoms/IncrementorInputField';
import CheckboxField from '~/components/atoms/CheckboxField';
import Button from '~/components/atoms/Button';
import useFormSubmit from '~/hooks/useFormSubmit';

const validationSchema = Yup.object({
  firstname: Yup.string().required('Required'),
  lastname: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email address').required('Required'),
  phone: Yup.string().required('Required'),
  number_of_bedrooms: Yup.string().required('Required'),
  property_address: Yup.string().required('Required'),
  of_properties: Yup.number().integer('Number of properties need to be a number').min(1, 'Number of properties need to be more than 0').required('Required'),
  privacy: Yup.boolean().oneOf([true], 'This field must be checked')
});

export default function PricingForm({setIsSubmitted, className}) {
  const [errorMessage, setErrorMessage] = useState('');
  const { post, response } = useFormSubmit({
    portalId: '7026924',
    formId: 'd58b322b-244a-43f8-8ed2-0e17b81cb140',
    setIsSubmitted
  });

  const getEstimate = async ({
    address,
    bedrooms
  }) => {
    if (!address) return false;

    try {
      const res = await fetch(`https://api.airdna.co/client/v1/rentalizer/estimate?access_token=ff6001b095364ed29d9a0f15b0679aaa&address=${encodeURIComponent(address)}&bedrooms=${bedrooms}`);
      const result = await res.json();
      const val = ((result?.property_stats?.revenue?.ltm / 12) * (1-0.15) / 1.3);
      return Math.ceil(val);
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  return (
    <div className={`px-8 py-12 md:py-16 md:px-20 bg-white relative ${className}`}>
      <Formik
        initialValues={{
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          property_address: '',
          number_of_bedrooms: '',
          is_your_property_available_365_days_a_year_: false,
          of_properties: '1',
          privacy: false,
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          // Get rental estimate
          const estimate = await getEstimate({
            address: values.property_address,
            bedrooms: values.number_of_bedrooms
          });

          // Post to hubspot
          delete values['privacy'];

          await post(values);

          if (response?.data?.status === 'error') {
            const errorHeading = response?.data?.message;
            const messages = response.data.errors.map(({message}) => message).join('<br/>')
            setErrorMessage(errorHeading+'<br /><br />'+messages);

          } else {
            navigate(
              "/pricing/result/",
              {
                state: {
                  ...values,
                  estimate
                },
              }
            )
          }

        }}
      >
        {(props) => (
          <Form id="pricing-form" className="grid grid-cols-12 gap-y-8">
            <InputField divclassname="col-span-12 " name="firstname" type="text" label="Your first name" />
            <InputField divclassname="col-span-12 " name="lastname" type="text" label="Your last name" />
            <InputField divclassname="col-span-12 " name="email" type="email" label="Your email address" />
            <InputField divclassname="col-span-12 " name="phone" type="text" label="Your phone number" />
            <AutocompleteInputField divclassname="col-span-12 " name="property_address" type="text" label="Property address" />
            <SelectField divclassname="col-span-12 " name="number_of_bedrooms" type="text" label="No. of bedrooms:"
              options={[
                {label: 'Studio', value: '0'},
                {label: '1', value: '1'},
                {label: '2', value: '2'},
                {label: '3', value: '3'},
                {label: '4', value: '4'},
                {label: '5+', value: '5'},
              ]}
            />
            <CheckboxField
              reverse={"true"}
              divclassname="col-span-12"
              name="is_your_property_available_365_days_a_year_"
              label="Is your property available 365 days a year?"
            />

            <IncrementorInputField divclassname="col-span-12 " name="of_properties" type="text" label="No. of properties in your portfolio: "  />

            <CheckboxField
              divclassname="col-span-12"
              name="privacy"
              label={`By submitting this form, you accept our <a href="/privacy-policy">Privacy Policy</a>`}
            />
            <div className="col-span-12">
              <Button type="submit" title="Get my results" className="w-full" />
              {errorMessage.length > 0 && (
                <div className="mt-8 text-error text-sm max-w-md" dangerouslySetInnerHTML={{ __html: errorMessage }}/>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
