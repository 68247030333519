import React from 'react'
import TextCard from '~/components/molecules/TextCard'
import Image from '~/components/atoms/Image'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function TwoColumnImageFeatures(props) {

  let reverse = false
  let noPad = false

  const section = props.twoColumnFeaturesWImage
  const backgroundColour = section?.backgroundColour

  const alignImage = section?.alignImage
  const imagePadding = section?.imagePadding

  if(alignImage === 'right'){
    reverse = true
  }

  if(imagePadding === 'none'){
    noPad = true
  }

  return (
    <section className={`${backgroundColour?.colour ? `bg-${backgroundColour.colour} py-20 md:py-36` : `my-20 md:my-36`} ${backgroundColour.colour === 'navy' && `text-white`} overflow-hidden`}>
      <div className="container">
        <div className={` ${section.textCard.alignText === 'center' ? 'max-w-3xl' : 'max-w-xl'} mx-auto mb-10 md:mb-32`}>
          <TextCard {...section.textCard} />
        </div>
        <div className="flex-col md:flex-row flex gap-y-8 md:gap-y-0 md:gap-x-24 justify-center items-center relative">
          {section.image && (
            <FadeInWhenVisible className={`w-full md:w-1/2 ${reverse ? `md:order-1 ml-auto ${noPad && 'md:w-[calc(50%+4rem)] md:mr-[-4rem] xl:w-[calc(50%+7.5rem)] xl:mr-[-7.5rem]'}` : `mr-auto ${noPad && 'md:w-[calc(50%+4rem)] md:ml-[-4rem] xl:w-[calc(50%+7.5rem)] xl:ml-[-7.5rem]'}`}`}>
              <Image data={section.image}  />
            </FadeInWhenVisible>
          )}
          <div className={`w-full md:w-5/12 relative`}>
            {section.features.map((feature, i) => (
              <FadeInWhenVisible className="mb-8 last:mb-0 flex items-start" key={i}>
                <Image data={feature.illustration} className="w-24 h-auto mr-7" objectFit="contain" imgClassName="h-auto" />
                <div>
                  <h5 className="text-h5 mb-2.5">{feature.heading}</h5>
                  <p>{feature.content}</p>
                </div>
              </FadeInWhenVisible>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
