import React from 'react'
import StoryPreview from '~/components/molecules/StoryPreview'
import usePartnerPreviews from '~/hooks/usePartnerPreviews';

export default function FeaturedStories({ className, heading, posts=[] }) {
  if (!posts.length) {
    // eslint-disable-next-line
    posts = usePartnerPreviews().slice(0,2);
  }
  return (
    <section className={`py-20 md:py-32 bg-rose ${className}`}>
      <div className="container">
        {heading && (<h2 className="text-h2 text-center mb-14">{heading}</h2>)}
        <div className="relative">
          <div className="grid grid-cols-12 gap-y-12 md:gap-y-0 md:gap-x-12 lg:gap-x-20  w-full">
            {posts.map((item, i) => (
              <StoryPreview key={item?.id+i} {...item} className="col-span-12 md:col-span-6 w-full" />
            ))}
          </div>
          <div className="hidden md:block col-span absolute w-px top-0 bottom-0 left-1/2 bg-navy bg-opacity-10"></div>
        </div>
      </div>
    </section>
  )
}
