import React from 'react'
import Image from '~/components/atoms/Image'
import FindCta from '~/components/molecules/FindCta';
import RentalCta from '~/components/molecules/RentalCta';
import TextCard from '~/components/molecules/TextCard';
import HelpFeatures from '~/components/molecules/HelpFeatures';
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function Hero(props) {
  const section = props.hero

  return (
    <section className="mb-20 md:mb-36 md:h-screen md:max-h-[860px] relative text-white">
      <div className="absolute inset-0">
        <Image loading={'eager'} data={section.image} className="w-full h-full" imageClassName="w-full h-full" />
        <div className="absolute inset-0 bg-navy opacity-10"></div>
        <div className="absolute inset-0 bottom-auto h-40 opacity-50 bg-gradient-to-b from-navy"></div>
        <div className="absolute inset-0 opacity-75 bg-gradient-to-r from-navy"></div>
      </div>

      <div className="container py-32 pb-20 md:pb-0 md:py-0 h-full relative flex flex-col justify-center">
        <div className="max-w-[30rem]">
          <TextCard {...section.textCard} />
        </div>
        {section.form && (
          <FadeInWhenVisible transition={{delay: '.25'}} className="max-w-4xl">
            {section.form === 'Find Home' ? (
              <FindCta className="text-white" />
            ) : ((section.form === 'Get Estimate') && (
              <RentalCta className="text-white" />
            ))}
          </FadeInWhenVisible>
        )}
        {section.showHelpFeatures && (
          <HelpFeatures />
        )}
      </div>

    </section>
  )
}
