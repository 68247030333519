import React, { useState } from 'react';
import { useField } from 'formik';

export default function IncrementorInputField(props) {
  const { label, divclassname, labelClassName, inputClassName, disabled, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  return (
    <div className={`group !border-error ${divclassname} `}>
      <div className="flex items-center">
        <label
          className={`text-sm duration-200 ease-in-out
          ${ disabled && 'text-opacity-30 group-hover:-translate-y-1/2' }
          ${ !isInvalid ? 'text-navy' : 'text-error'} ${labelClassName}`}
        >
          {label}
        </label>
        <div className="flex items-center ml-auto">
          <button type="button" className="w-5 h-5 border border-navy border-opacity-30 relative flex items-center justify-center" onClick={(e) => {!isNaN(parseInt(meta.value)) && parseInt(meta.value) > 1 && helpers.setValue(parseInt(meta.value) - 1)}}>
            <div className="w-3 h-px bg-navy"></div>
          </button>
          <input
            type="number"
            min="1"
            className={`bg-transparent outline-none transition-colors duration-200 ease-in-out h-full w-8 text-center disabled:text-opacity-30 font-medium
              ${disabled && 'group-hover:border-opacity-30'}
              ${!isInvalid ? '' : 'border-b-error'} ${inputClassName}`
            }
            {...field}
            {...other}
          />
          <button type="button" className="w-5 h-5 border border-navy border-opacity-30 relative"  onClick={(e) => !isNaN(parseInt(meta.value)) &&  helpers.setValue(parseInt(meta.value) + 1)}>
            <div className="w-3 h-px bg-navy absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 "></div>
            <div className="w-3 h-px bg-navy absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rotate-90"></div>
          </button>
        </div>
      </div>

      {isInvalid && (
        <div className="text-[12px] text-error flex font-medium items-center mt-1">
          <div className="w-2 h-2 relative mr-1 mt-px">
            <div className="w-full h-0.5 bg-error block absolute top-1/2 transform -translate-y-1/2 rotate-45"></div>
            <div className="w-full h-0.5 bg-error block absolute top-1/2 transform -translate-y-1/2 -rotate-45"></div>
          </div>
          {meta.error}
        </div>
      )}
    </div>
  );
}
