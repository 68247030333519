import React from 'react'
import TextLink from '~/components/atoms/TextLink'
import TextCard from '~/components/molecules/TextCard'
import Image from '~/components/atoms/Image'
import FadeInWhenVisible from '~/components/FadeInWhenVisible'

export default function IntroImages(props) {

  const section = props.introImages
  return (
    <section className="my-20 md:my-36">
      <div className="container mb-16">
        <div className="max-w-xl mx-auto ">
          <TextCard className="text-center" {...section.textCard} />
        </div>
      </div>
      {}
      <FadeInWhenVisible className="container px-0">
        {section.desktopImage && <Image data={section.desktopImage} className={`hidden md:block w-full h-auto`} />}
        {section.mobileImage && <Image data={section.mobileImage} className={`block md:hidden w-full h-auto`} />}
      </FadeInWhenVisible>
    </section>
  )
}
