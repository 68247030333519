import React, { useState } from 'react';
import { useField } from 'formik';

export default function InputField(props) {
  const { label, divclassname, labelClassName, inputClassName, disabled, ...other } = props;
  const [field, meta, helpers] = useField(props);
  const isInvalid = meta.touched && meta.error;

  return (
    <div className={`flex flex-col justify-center group !border-error ${divclassname} `}>
      <div className="relative">
        <label
          className={`text-sm pointer-events-none absolute top-1/2 duration-200 ease-in-out transform transition-transform -translate-y-1/2 group-focus-within:-translate-y-10 group-hover:-translate-y-10
          ${ disabled && 'text-opacity-30 group-hover:-translate-y-1/2' }
          ${ (meta.value?.length > 0 || isInvalid) && '!-translate-y-10'}
          ${ !isInvalid ? 'text-navy' : 'text-error'} ${labelClassName}`}
        >
          {label}
        </label>
        <input
          className={
            `bg-transparent border-b outline-none group-hover:border-opacity-100 focus:border-opacity-100 transition-colors duration-200 ease-in-out py-3.5 w-full border-b-navy border-opacity-30 disabled:text-opacity-30
            ${disabled && 'group-hover:border-opacity-30'}
            ${!isInvalid ? '' : 'border-b-error'} ${inputClassName}`
          }
          {...field}
          {...other}
        />
      </div>

      {isInvalid && (
        <div className="text-[12px] text-error flex font-medium items-center mt-1">
          <div className="w-2 h-2 relative mr-1 mt-px">
            <div className="w-full h-0.5 bg-error block absolute top-1/2 transform -translate-y-1/2 rotate-45"></div>
            <div className="w-full h-0.5 bg-error block absolute top-1/2 transform -translate-y-1/2 -rotate-45"></div>
          </div>
          {meta.error}
        </div>
      )}
    </div>
  );
}
